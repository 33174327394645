/* The side navigation menu */
.sidebar {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    width: 160px;
    overflow: auto;
    position: fixed;
    height: 100%;
    margin-left: 40px;
    margin-bottom: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* Sidebar links */
.sidebar a,
.sidebar .main {
    display: block;
    color: black;
    font-size: 10pt;
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;
    font-family: "CooperHewitt-Book";
}

.sidebar .main2 {
    display: none;
}

/* Active current link */
.sidebar a.active:not(.main, .main2) {
    white-space: pre-wrap;
    color: #00FF00;
    font-family: 'CooperHewitt-Bold-Italic';
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

/* Links on mouse-over */
.sidebar a:hover:not(.main, .main2) {
    color: #00FF00;
}

.sidebar-top a {
    padding: 5px;
    padding-right: 0;
    padding-left: 0;
    letter-spacing: 2px;
}

.sidebar .main,
.sidebar .main2 {
    font-size: 17pt;
    font-weight: bold;
    font-family: 'Source Serif Pro Regular';
}

.sidebar-main {
    margin-top: auto;
}

.sidebar-main a {
    letter-spacing: 2px;
    padding: 5px;
    padding-right: 0;
    padding-left: 0;
}

.sidebar-bottom {
    margin-top: auto;
    letter-spacing: 2px;
    font-size: 10pt;
    margin-bottom: 16px;
}

.sidebar-bottom p {
    margin: 0;
    padding: 0px;
}

.sidebar-bottom p,
.sidebar-bottom a {
    padding: 5px;
    padding-left: 0;
    padding-right: 0;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .sidebar .main {
        display: none;
    }

    .sidebar .main2 {
        display: block;
        font-size: 10pt;
        padding: 15px;
    }

    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        float: right;
        margin-top: 0;
        margin-bottom: 0;
    }

    .sidebar-main a {
        float: left;
        padding: 15px;
        letter-spacing: normal;
    }

    .sidebar-top {
        display: none;
    }

    .sidebar-bottom {
        display: none;
        margin: 0;
    }
}