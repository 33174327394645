.footer {
    display: none;
}

@media screen and (max-width: 700px) {
    .footer {
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

    .footer a,
    .footer p {
        font-size: 10pt;
        margin: 0;
        padding: 16px;
        text-decoration: none;
        float: left;
        color: black;
    }

    .footer a:hover {
        color: black;
    }

    .footer a.active {
        white-space: pre-wrap;
        color: #00FF00;
        font-family: Bold italic;
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
    }
}