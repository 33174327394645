th {
    text-align: left;
    padding-top: 30px;
}

td {
    min-width: 80px;
}

p {
    text-align: justify;
}