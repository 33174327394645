.carousel {
    width: 100%;
}

.carousel img {
    width: 100%;
    display: block;
}

.divider {
    width: 100%;
    height: 1.5px;
    margin-top: 75px;
    margin-bottom: 75px;
    background-color: #e0e0e0;
}

.post-text {
    white-space: pre-wrap;
    display: block;
}

.post-info p {
    font-family: "CooperHewitt-Book";
    margin: 0;
}

.post-info .title {
    font-size: 10pt;
    font-weight: lighter;
}

.post-info .description {
    font-size: 8pt;
    font-weight: lighter;
}