#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Source Serif Pro Regular';
}

@font-face {
    font-family: "CooperHewitt-Book";
    src: local("CooperHewitt-Book"),
        url("./fonts/cooper-hewitt/CooperHewitt-Book.otf") format("truetype");
}

@font-face {
    font-family: "CooperHewitt-Bold-Italic";
    src: local("CooperHewitt-Bold-Italic"),
        url("./fonts/cooper-hewitt/CooperHewitt-BoldItalic.otf") format("truetype");
}