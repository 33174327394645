.app {
    box-sizing: border-box;
    margin-left: 200px;
    margin-right: 20px;
    padding-top: 20px;
    max-width: 960px;
}

.line {
    margin-top: 30px;
    margin-bottom: 15px;
    margin-left: 12px;
    width: 150px;
    height: 1px;
    background-color: black;
}

@media screen and (max-width: 700px) {
    .app {
        margin-left: 20px;
    }

    .line {
        display: none;
    }
}